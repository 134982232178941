@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.mainpopup {
  margin: auto;
  height: 60%;
  overflow-y: auto;
  width: 400px;
  padding: 30px;
  background: var(--popup--background);
  border-radius: 5px;
  text-align: center;
}

.header {
  font-family: "nunito";
  font-size: 25px;
  font-weight: 400;
  padding-bottom: 20px;
}

.title {
  width: 100%;
  border: 1px solid #6a6a6a;
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
}

.condition {
  font-family: "nunito";
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 10px;
}

.textarea {
  width: 100%;
  border: 1px solid #6a6a6a;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0px;
  resize: none;
}

.fileinput {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 40px 0px;
  border: 1px dashed #6a6a6a;
}

.browse:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px 0px;
}

.submit {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
  &:disabled {
    background-color: grey;
  }
}

.cancel {
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.submit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.cancel:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.caution {
  font-family: "nunito";
  font-size: 12px;
  font-weight: bold;
  padding-top: 10px;
  color: red;
}
