.liveSession__table {
  border-collapse: separate;
  border-spacing: 0 2em;
  width: 100%;

  tr > :first-child {
    font-size: 16px;
    width: 150px;
  }
  tr > :last-child {
    font-size: 16px;
  }
  .LDescription {
    padding: 1rem;
    &:focus {
      outline: none;
    }
  }
  input[title="liveSessionInput"] {
    width: 100%;
    outline: none;
    border: 1px solid #777;
    padding: 0.6rem 0.6rem;
    &:focus {
      outline: none;
    }
  }
}

.add {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}
