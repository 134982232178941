.mainpopup{
    width: 80%;
    height: 90%;
    overflow-y: auto;
    background: var(--popup--background);
    padding: 30px;
    border-radius: 5px;
    margin: auto;
    // text-align: center;
}

.closeholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.courseselect{
    height: 30px;
    background: transparent;
    width: 300px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 16px;
    padding: 3px;
    cursor: pointer;
}
.select{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:15px
}

.label{
    font-size: 20px;
    font-weight: 300;
}
.option{
    width: 100px;
    overflow: auto;
}
.closebutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.root{
    padding-top: 20px;
}

.toggleholder{
    display: flex;
    flex-direction: column;
    align-items: middle;
    justify-content: space-between;
    padding: 10px 0px;
    width: 100%;
    gap: 30px;
}

.toggleinternal{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 10px;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
}

