.inputholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .inputbox {
    width: 100%;
    border: 1px solid #6a6a6a;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    margin: 10px 0px;
  }
  .save {
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    margin-right: 18px;
  }
  
  .cancel {
    background: transparent;
    border: 1px solid var(--button-background);
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-background);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
  }
  
  .save:hover {
    cursor: pointer;
    transform: scale(1.1);
  }