@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer {
    background: whitesmoke;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 30px;
    margin: 0px;
  }

  .headerbutton{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
  }

  .button{
    background: #e35f14;
    border: none;
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: white;
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    margin-right: 30px;
    padding: 0px 20px;
  }

.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.application{
  padding-top: 20px;
}

