@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.maincontainer {
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
  }

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.middlescreen{
    padding: 20px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.leftscreen{
    width: 40%;
    height: 75vh;
    overflow-y: auto;
    border-radius: 5px;
}

.rightscreen{
    width: 60%;
}

.dropoption{
    font-size: 16px;
    color: #6a6a6a;
    font-weight: 500;
    font-family: "nunito";
    padding: 10px
}

.dropoption:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.dropoptionactive{
    font-size: 16px;
    color: white;
    font-weight: 500;
    font-family: "nunito";
    padding: 10px;
    background: var(--sub-heading-color);
}

.dropoptionactive:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.heading{
    font-size: 16px;
    color: var(--sub-heading-color);
    font-weight: 500;
    font-family: "nunito";
    padding: 10px;
    background: #fff;
}

.heading:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.headingactive {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    font-family: "nunito";
    padding: 10px;
    background: var(--sub-heading-color);
}

.headingactive:hover {
    cursor: pointer;
    transform: scale(1.01);
}