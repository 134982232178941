.content-divider::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 48%;
  right: 0;
  height: 85%;
  width: 2px;
  color: brown;
  background-color: #000;
  /* transform: translateY(100%); */
}
