.classInput {
  border: none;
  width: 100%;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
  font-weight: 200px;
  outline: none;
}
.classInputFeedback {
  border: 1px solid #ccc;
  width: 94%;
  box-shadow: 0px 0px 5px 0px lightgrey;
  background: white;
  height: 30px;
  font-size: 15px;
  padding-left: 10px;
  font-weight: 200px;
  right: 0;
  align-self: flex-end;
  outline: none;
}

.classTextarea {
  width: 100%;
  resize: none;
  outline: none;
  height: 50px;
  border: 0;
  padding: 1rem 1rem;
  font-size: 15px;
  box-shadow: 0px 0px 5px 0px lightgrey;
}

.slide-container {
  background-color: transparent;
  width: 850px;
  padding: 1rem 8rem;
  margin: 1rem 0rem;

  @media screen and (min-width: 1px) and (max-width: 500px) {
    margin: 0;
  }

  .slider-container {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
  }
}

.color-red {
  color: red;
  font-size: 1.5rem;
}
