.maincontainer {
  background: var(--app-background);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  margin: 0px;
}

.field {
  display: flex;
  // justify-content: flex-end;
  // align-content: center;
  margin: auto;
  gap: 20px;
}

.btn_color {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.mainpopup {
  height: 600px;
  width: 60%;
  background: white;
  margin: auto;
  padding: 20px;
}

#invoicepdf {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background: white;
  color: black;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}