@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


//Begin template
body {
  background: #eee;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

.container {
  
  margin: 0 auto;
}

.querydiv{
  border-radius: 5px;
  border: 1px solid lightgrey;
}

.popupcontainer{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.embedsubmitfield{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  height: 150px;
  padding: 20px;
}

.discussionInput{
  border: none;
  font-family: "nunito";
  font-size: 14px;
  height: 40px;
}

.discussionInput:focus{
  outline: none;
}



.closeicon:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.buttonPost{
  background: var(--button-background);
    border: none;
    border-radius: 5px ;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    
}

.buttonPost:hover{
  cursor: pointer;
  transform: scale(1.1);
}


.disscussionInputPopup {
  width: 100%;
  padding: 15px;
  border-radius: 5px ;
  height: 150px !important;
  margin-top: 10%;
}

.buttonCancel{
  position: absolute;
    right: 15px;
    top: 57px;
    color: #3372B5 !important;
  background-color: white !important;
  border-radius: 8px !important;
  font-size: 18px;
  height: 28px;
  line-height: 13px;
  border: 1px solid #3372B5;
 
  font-weight: bold;
}

// .embedsubmitfield {
//   position: relative;
  
  
//   input {
//     width: 100%;
//     padding: 15px;
//     border-radius:6px ;
//     height: 120px;
//     border: 1px solid #6a6a6a;
    
    
//   }

//   button {
//     position: absolute;
//     right: 20px;
//     top: 55px;
//     font-weight: 500px;
//     font-family: "nunito";
//     margin-top: 20px;
//     color: #fff !important;
//     background-color: var(--button-background) !important;
//     border-radius: 5px !important;

    
    
//     //Only styling from here, you can discard this
//     -webkit-appearance: none;
//     -moz-appearance: none;
    
//     border: none;
//     background: #ededed;
//     border-radius: 3px;
//     padding: 10px;
    
//     transition: all .2s;
    
    
//   }
// }
.messageContent{
    font-size: large !important;
    font-family: "nunito";
}
.replyIcon{
    width: 26px;
    height: 26px;
}
.deleteIcon{
  width: 20px;
    height: 20px;
    margin-top:45px ;
    margin-left: -20px;
}

.queryfield{
  height: 60vh;
  overflow-y: auto;
  border-radius: 5px;
}

.buttonholder{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px 0px;
}


.submit{
  background: var(--button-background);
  border: none;
  border-radius: 5px ;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.submit:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.cancel{
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px ;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.cancel:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.btn_color{
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.btn_colordis {
  background-color: grey;
  color: white;
  cursor: not-allowed;
  font-family: "nunito";
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
  border: none;
  height: 30px;
  width: fit-content;
}

.btn_color:hover{
  cursor: pointer;
  transform: scale(1.1);
}
