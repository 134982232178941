.maindiv {
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containermain {
    width: 50%;
    height: fit-content;
    background: var(--popup--background);
    border-radius: 2px;
    justify-content: center;
    margin: auto;
    padding: 20px;
}

.iconcontainer {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.namebutton {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px 50px
}

.username {
    font-size: 20px;
    font-family: "nunito";
    color: #000 !important;
    font-weight: 400;
}

.userbutton {
    font-family: "nunito";
    border: 1px solid #04549c;
    border-radius: 5px;
}

.maindivision {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: auto;
    padding: 20px;
}

.imageholder {
    width: 30%;
    vertical-align: middle;
}

.profile {
    width: 200px;
    height: 200px;
    border: 2px solid grey;
    border-radius: 50%;
    margin-top: 80px;
    margin-left: 30px;
}

.form {
    width: 90%;
    text-align: center;
    margin: 10px auto;
}

.field {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin: auto;
    gap: 20px;
}

.submit {
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 25px;
    width: 70px;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
}

.submit:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.disablebutton {
    font-family: "nunito";
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    font-weight: 400;
    font-size: 18px;
    background: var(--button-background);
}

.disablebutton:hover {
    font-family: "nunito";
    cursor: pointer;
    transform: scale(1.1);
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    font-weight: 400;
    font-size: 18px;
    background: var(--button-background);
}

.btn_color {
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 35px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.btn_colordis {
    background-color: grey;
    color: white;
    cursor: not-allowed;
    font-family: "nunito";
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    border: none;
    height: 35px;
    width: fit-content;
}

.btn_color:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.inputcontainer {
    border: 1px solid lightgrey;
    width: 80%;
    border-radius: 5px;
    background: white;
    height: 30px;
    margin-bottom: 20px;
    font-size: 15px;
    padding-left: 10px;
    font-weight: 200px;
}

.textareacontainer {
    border: 1px solid lightgrey;
    width: 80%;
    border-radius: 5px;
    background: white;
    height: 80px;
    margin-bottom: 20px;
    font-size: 15px;
    padding-left: 10px;
    font-weight: 200px;
}