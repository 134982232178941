@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer{
    background: #f5f5f5;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.subcontainer{
    padding: 0px 25px 25px 25px;
    width: 100%;
    z-index: 1;
    overflow-y: auto;
}

.heading{
    font-size: 30px;
    text-align: left;
    color: var(--sub-heading-color);
    font-family: "nunito";
}

.backbuttonholder{
    padding: 25px;
    width: 100%;
    position: sticky;
    top: 0px;
    background: whitesmoke;
    z-index: 100;
}

.backbutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}