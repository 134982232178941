.section {
  display: flex;
  gap: 2rem;

  &_inputs {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem;
    gap: 2rem;

    width: 70%;
    &_items {
      display: flex;
      gap: 2rem;
      align-items: center;
      h3 {
        flex-basis: 20%;
        font-size: 1.5rem;
      }
      input {
        flex-grow: 1;
        height: 25px;
        // border-radius: 4px;
        padding: 0.5rem 1rem;
        outline: none;
        font-size: 1.45rem;
        background-color: var(--app-background);
        border: none;
        &:focus {
          outline: none;
        }
      }
      textarea {
        flex-grow: 1;
        resize: none;
        height: 130px;
        padding: 0.5rem 1rem;
        // border-radius: 4px;
        background-color: var(--app-background);
        font-size: 1.45rem;
        outline: none;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
  &_image {
    // background-color: green;
    width: 30%;
    min-height: 220px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem;
    &_fit {
      border-radius: 4px;
      margin: 1rem 1rem 0 1rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.shadow-course {
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1rem;
}
