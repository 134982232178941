@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.popupcontainer{
    width: 30%;
    height: 500px;
    overflow-y: auto;
    margin: auto;
    padding: 20px;
    background: var(--popup--background);
}

.iconholder{
    display: flex;
    justify-content: flex-end;
    align-items: middle;
}

.icon{
    height: 30px;
    width: 30px;
}

.icon:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.header{
    font-family: "nunito";
    font-size: 20px !important;
    padding: 20px 0px !important;
    font-weight: 400 !important;
    padding-left: 5px;
}

.batch:hover{
    cursor: pointer;
    transform: scale(1.01);
}

.table{
    padding-top: 15px;
}