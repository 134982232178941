@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.main {
  background: whitesmoke;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 0px;
  overflow-y: auto;
}

.firsthalf {
  width: 100%;
}

.header {
  font-family: "nunito";
  font-size: 26px;
  padding: 20px 0px !important;
  font-weight: 400;
  padding-left: 5px;
}

.containers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.numbers {
  padding-top: 35px;
  font-family: "nunito";
  color: black !important;
  font-size: 25px;
}

.types {
  font-family: "nunito";
  font-size: 18px;
  font-weight: 200;
  color: black;
  padding-top: 10px;
  padding-bottom: 30px;
}

.graphholder {
  height: 400px;
  width: 100%;
  padding: 50px;
  background: white;
  box-shadow: 0px 0px 4px 0px;
}

.secondhalf {
  padding-top: 20px;
}

.paperholder {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.holder {
  width: 100%;
  height: fit-content;
}

.anchortag {
  font-size: 13px;
  cursor: pointer;
  border: 1px solid black;
  color: #fff;
  height: 35px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  color: blue;
}

.anchortag:hover {
  background-color: #6E2B69;
  color: #fff;
}