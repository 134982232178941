.assessmentHeader {
  font-weight: bold !important;
  font-weight: medium !important;
}

.unitAssesment {
  margin-top: 20px !important;
}

.closebtn {
  background-color: transparent !important;
  border: none;
  width: 10px;
  margin-top: -10px;
  right: -11px !important;
  top: 0px !important;
  color: white !important;
  font-size: 15px !important;
}
.body {
  background-color: #252d4a;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  border-radius: 15px;
  color: #ffffff;
}

.app1 {
  background-color: #252d4a;
  width: 100%;
  min-height: 60vh;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  max-height: 95vh;
  overflow-x: hidden;
  align-items: center;
  // justify-content: center;
}
.scoresection {
  display: flex;
  min-height: 50vh;
  font-size: 24px;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  justify-content: center;
}
.scrollQuiz {
  overflow-y: auto;
  max-height: 400px;
  overflow-x: hidden;
}
/* QUESTION/TIMER/LEFT SECTION */
.questionsection {
  width: 100%;
  position: relative;
}
.questioncount {
  margin-bottom: 20px;
}
.questioncount span {
  font-size: 28px;
}
.questiontext {
  margin-bottom: 12px !important;
  font-size: 14px;
}
.timertext {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}
/* ANSWERS/RIGHT SECTION */
.answersection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.btnQuiz {
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
}
.buttonQuiz {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
  margin: 0.5rem;
  text-align: center;
}

.btncenter {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  border: 5px solid #234668;
  cursor: pointer;
  margin: 0.5rem;
  text-align: center;
}
.correct {
  background-color: #2f922f;
}
.incorrect {
  background-color: #ff3333;
}

.buttonQuiz svg {
  margin-right: 5px;
}

.paper {
  position: "absolute";
  width: 400;
  border: "2px solid #000";
  background-color: violet;
}
.nextbtn {
  width: 110px;
  color: white;
  font-size: 12px;
  margin-top: 47px;
}
/* .nextbtn:disabled {
  width: 110px;
  color: white;
  font-size: 12px;
  margin-top: 47px;
} */
.grid1 {
  padding: 0 45px;
}
.grid2 {
  text-align: center;
}
.MuiListItemText-primary {
  margin-top: unset !important;
  margin-bottom: unset !important;
}
.drawer > .MuiSvgIcon-root {
  fill: white !important;
}

.MuiListItembutton {
  border: 1px solid white !important;
  margin: 0 73px !important;
  width: 80% !important;
  font-size: 20px !important;
}
.heading {
  text-align: center;
  margin-bottom: 12px;
}
.Checkbox {
  margin: 0px 10px 0px !important;
  cursor: pointer;
}
.CheckboxLabel {
  cursor: pointer;
  display: block;
  font-weight: normal;
}
.bordercolor {
  border: 2px solid yellow;
}
.MuiIconButtonlabel {
  display: none !important;
}

.labelstyle {
  width: 100% !important;
}
.btn-secondary {
  width: 100% !important;
}
.btngroupvertical {
  display: inline-block !important;
}
.btn-secondary {
  width: 100%;
}
input:checked + label {
  //border: 5px solid #f5a138;
  /* font-weight: bold; */
}
.textarea {
  min-height: 100px !important;
  max-height: 250px !important;
  overflow: hidden;
  font-size: 20px !important;
  width: 100% !important;
}
.assesstimertext {
  display: block;
  justify-content: center;
  text-align: center;
  color: black;
  margin-bottom: -86px;
}
.assesslist {
  border: 1px solid #ddd;
  padding: 10px;
}
.assesscon {
  margin: 0;
  max-width: unset !important;
}
.closestyle {
  width: 100%;
  display: block !important;
  text-align: end;
  margin: 0px 30px 0px 30px !important;
}
.closestyle1 {
  width: 100%;
  display: block !important;
  position: absolute;
  top: -10px;
  right: -8px;
  text-align: right;
  color: #fff;
}
.matchThe {
  border: 1px solid white;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  font-size: x-large;
  border-color: white;
}
.filename {
  font-size: xx-large;
  color: #fff;
}
