.StyledGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 55px);
  grid-row-gap: 1rem;
}

.StyledGrid2 {
  display: grid;
  grid-template-columns: minmax(60px, 150px) 300px;
  grid-column-gap: 1rem;
  align-items: center;
  p {
    text-align: right;
  }
}
