.headerStyle {
  background-image: linear-gradient(#3372b5, #01498e) !important;
  height: 70px !important;
  font-size: 17px !important;
}
.loginButton {
  color: #e35f14 !important;
  border-style: solid !important;
  border-color: #e35f14 !important;
  height: 30px !important;
  line-height: 0px !important;
  margin-top: 20px !important;
  background-color: white !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border-width: 1.5px !important;
  font-weight: bolder !important;
}
.emailStyle {
  fill: #e35f14;

  width: 2px;
  height: 2px;
}
.spinnerStyle {
  width: 40px !important;
  height: 40px !important;
  /* left: 200px; */
  margin-left: 252px !important;
  z-index: 999 !important;
  margin-top: 187px !important;
  overflow: visible !important;
  position: fixed !important;
}
.spinnerStyle:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.labelForIssue {
  font-size: 12.5px !important;

  white-space: nowrap !important;
  height: 20px !important;
  color: #f64242 !important;
}
.hover {
  display: none;
}

.emailIc {
  width: 30px;
  height: 30px;
  position: absolute !important;
  left: 17%;
}
.passIc {
  width: 26px;
  height: 26px;
  position: absolute !important;
  left: 17%;
}

.diverforspinner{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: transparent;
  height: 100vh;
  width: 100%;
}

.spinnerStyle {
  width: 40px !important;
  height: 40px !important;
  /* left: 200px; */
  margin-left: 252px !important;
  z-index: 999 !important;
  margin-top: 187px !important;
  overflow: visible !important;
  position: fixed !important;
}
.spinnerStyle:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.labelForIssue {
  font-size: 12.5px !important;

  white-space: nowrap !important;
  height: 20px !important;
  color: #f64242 !important;
}

.emailIc {
  width: 30px;
  height: 30px;
  position: absolute !important;
  left: 17%;
}
.passIc {
  width: 26px;
  height: 26px;
  position: absolute !important;
  left: 17%;
}

.maincontainer{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* linear-gradient(#3372B5,#01498e); */
