.card {
  width: 88% !important;
  height: 300px !important;
  margin-left: 10px !important;
  border-radius: 10px !important;
}
.imageCard {
  width: 100% !important;
  object-fit: cover !important;
  height: 180px !important;
}
.topicName {
  margin-top: 20px !important;
  font-size: 15px !important;
  float: left !important;
  margin-left: 20px !important;
  font-weight: bold !important;
  color: #3372b5 !important;
  margin-right: 20px !important;
}
.slick-dots {
  bottom: 47px !important;
}
.slick-arrow {
  color: black !important;
}
.slick-prev:before,
.slick-next:before {
  color: revert !important;
}
.projectTitle {
  font-size: 36px !important;
  color: #13316c !important;
  font-family: "MyWebFont" !important;
  font-weight: bold !important;
  width: 40% !important;
  position: absolute !important;
  top: 0% !important;
  left: 25% !important;
  transform: translate(-50%, 321%) !important;
  text-align: center !important;
  z-index: 10 !important;
  margin: 192px -22% !important;
  line-height: 35px !important;
}
.loginButtonTitle {
  color: white !important;
  border-style: groove !important;
  border-color: #e35f14 !important;
  height: 30px !important;
  line-height: 0px !important;
  margin-top: 22px !important;
  background-color: #e35f14 !important;
  font-size: 16px !important;
  border-radius: 3px !important;
  font-weight: bold;
}
/* style={{   fontSize: "26px",
    color: "#E35F14",
   
    fontWeight: "bold",
    width: "40%",
    position: "absolute",
    top: "0%",
    left: "25%",
    
    textAlign: "center",
    zIndex: "10",
    margin: "192px -22%",
    lineHeight: "35px"}} */

.diverforspinner{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100vh;
  width: 100%;
}

.spinnerStyle {
  width: 40px !important;
  height: 40px !important;
  /* left: 200px; */
  margin-left: 252px !important;
  z-index: 999 !important;
  margin-top: 187px !important;
  overflow: visible !important;
  position: fixed !important;
}
.spinnerStyle:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.labelForIssue {
  font-size: 13px;
  white-space: unset !important;
  height: 20px !important;
  color: #f64242 !important;
  margin-left: 100px !important;
}

.emailIc {
  width: 30px;
  height: 30px;
}

.loginbutton:hover{
  cursor: pointer;
  transform: scale(1.1);

}

.cls1{
  fill:none;
  stroke: var(--header-color);
}

.cls2{
  fill:none;
  stroke: var(--header-color);
}

.passIc {
  width: 30px;
  height: 30px;
  fill: var(--header-color);
  stroke: none;
}

.lockcolor{
  color: var(--header-color);
}

.maincontainer{
  height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.faqholder{
  margin-top: 15px;
}

.faqlink {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 15px;
}