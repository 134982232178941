.management {
  padding: 0 0rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.h1 {
  margin-bottom: 2rem;
}

.management_info {
  max-height: auto;
  margin-bottom: 4rem;
  .flex_box {
    display: flex;
    gap: 1rem;
    max-height: auto;
    height: auto;

    &__container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 2rem;
      flex: 0 0 66%;
      overflow: hidden;
    }
    .management__image {
      flex: 0 0 29%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      height: 200px;
    }
  }
}

.text_align {
  text-align: left;
  padding: 0 3rem;
}
.management__image {
  flex: 0 0 29%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  height: 200px;
}
.flex_box {
  display: flex;
  gap: 1rem;
  max-height: auto;
  height: auto;
}
.management_info {
  max-height: auto;
  margin-bottom: 4rem;
}
.time_line {
  display: flex;
}
.inputsize {
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0.0211rem;
  width: 100% !important;
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: var(--para-size-medium);
  }
}
.add {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.tabContainer {
  position: relative;

  .observerEmailContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;

    text-transform: uppercase;

    &:hover .editContainer {
      display: block;
    }
  }

  .editContainer {
    display: none;
    cursor: pointer;
  }

  .Add_observer {
    // min-width: 150px;
    font-size: 15px;
    cursor: pointer;
    padding: 0.5em;
    background-color: var(--button-background);
    color: var(--button-text);
    border-radius: 3px;

    &:focus {
      outline: 1px solid black;
    }
  }

  .position {
    position: absolute;
    right: 0;
    top: 10px;
    border: 0;
  }
}

.dropdown {
  width: 100%;
  padding: 5px;
  font-size: 18px;

  .option {
    font-size: 18px;
  }
}

.Add_observer {
  // min-width: 150px;
  font-size: 15px;
  cursor: pointer;
  padding: 0.5em;

  background-color: var(--button-background);
  color: var(--button-text);
  border-radius: 3px;

  &:focus {
    outline: 1px solid black;
  }
}

.submit{
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.cancel{
  background: transparent;
  border: 1px solid var(--button-background);
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-background);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.submit:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.cancel:hover{
  cursor: pointer;
  transform: scale(1.1);
}
