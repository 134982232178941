@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer {
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
  }

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
}

.tabholder{
    padding: 20px 0px;
}

.topsection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    padding: 20px 0px;
}

.container{
    height: 120px;
    width: 200px;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.title{
    font-family: "nunito";
    font-size: 14px;
    font-weight: 500;
    color: black !important;
    padding: 10px 0px;
}

.icon{
    width: 30px;
    height: 30px;
    align-items: center;
    margin: auto;
    padding-top: 10px;
}

.percent{
    font-family: "nunito";
    font-size: 14px;
    font-weight: 500;
    color: black;
    padding-bottom: 20px;
}

.middlesection{
    height: 200px;
    padding: 30px 0px;
}

.table{
    padding-top: 20px;
}

.image {
    width: 200px;
    height: 100px;
    border-radius: 10px;
}

.programname:hover{
    cursor: pointer;
    transform: scale(1.01);
}