@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer {
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
}

.heading {
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.gridholder {
    padding-top: 30px;
    height: 90%;
}

.gridcontainer {
    padding: 20px;
    height: 100%;
}

.right_size {
    height: 2rem;
    width: 2rem;
    margin-left: 10px;
}

.Wrong_size {
    height: 2rem;
    width: 2rem;
    margin-left: 10px;
}

.rightsection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.btn_color {
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    margin-top: 20px;
  }
  
  .btn_colordis {
    background-color: grey;
    color: white ;
    cursor: not-allowed;
    font-family: "nunito";
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    border: none;
    height: 30px;
    width: fit-content;
  }
  
  .btn_color:hover {
    cursor: pointer;
    transform: scale(1.1);
  }