.clock-size {
  height: 30px !important;
  width: 30px !important;
  margin-right: 10px;
}

.cls1,
.cls2 {
  fill: none;
  stroke: #9e9e9e;
  stroke-miterlimit: 10;
  stroke-width: 4.55px;
}
.cls2 {
  stroke-linecap: round;
}
.edit-icons,
.hide__edit-icon {
  display: none;
  cursor: pointer;
}

.edit-icons:hover .hide__edit-icon {
  transition: all 5s ease 4s;
  display: inline;
  cursor: pointer;
}
.edit-icons:hover {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}
.btn-reset {
  // padding: 0;
  // margin: 0;
  background: transparent;
  margin: 0 1.3rem;
}
.btn-border {
  border: 1px solid #f18121;
  border-radius: 4px;
}
.size {
  width: 2.4rem;
  height: 2.4rem;
}
.hide__edit-disc {
  display: none;
}
.hide__edit-week {
  display: none;
}
.week-hover:hover .week-border {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}
.week-hover:hover .hide__edit-week {
  display: block;
}
.disc-border:hover {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}
.disc-border:hover .hide__edit-disc {
  display: block;
}

.hide__edit-wise {
  display: none;
  cursor: pointer;
}

.discc-border:hover .hide__edit-wise {
  display: block;
  cursor: pointer;
}

.hide_edit-title {
  display: none;
}

.title-hover:hover .hide_edit-title {
  display: block;
}

.hide_image-icon {
  display: none;
}

.image-hover:hover .hide_image-icon {
  display: block;
}

.unit-edit-icon {
  display: none;
}
.unit-hover:hover .unit-edit-icon {
  display: block;
}
.semester-edit {
  display: none;
}
.semester-hover:hover {
  color: #f18121;
}
.semester-hover:hover .semester-edit {
  color: #f18121;
  display: block;
}
.btn_color{
  background: var(--color-grey-dark-1) !important;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: fit-content;
  color: var(--button-text) !important;
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
}

.btn_colordis {
  background-color: grey !important;
  color: white !important;
  cursor: not-allowed;
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 0px 20px;
  border: none;
  height: 30px;
  border-radius: 5px;
  width: fit-content;
}

.btn_color:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.draganddrop_object__3Eiz5{
  height: unset !important;
}