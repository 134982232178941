
.graphbutton {
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--before-color);
  font-family: "nunito";
  font-size: 15px;
  margin-left: 10px;
  margin-top: 20px;
  width: 80px;
  padding-bottom: 5px;
  font-weight: 400px;
}

.graphbuttonactive {
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--header-color);
  font-family: "nunito";
  font-size: 15px;
  margin-left: 10px;
  margin-top: 20px;
  width: 80px;
  padding-bottom: 5px;
  font-weight: 400px;
}
