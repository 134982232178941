@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.super-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.bread{
  color: var(--sub-heading-color);
  font-family: "nunito";
  font-size: 12px;
  font-weight: bold;
}

.span{
  color: grey;
  font-family: "nunito";
  font-size: 12px;
  font-weight: bold;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;

  &__icon {
    height: 2.75rem;
    width: 2.75rem;
  }
}
.dropdown.icon {
  display: none !important;
  color: #c4c4c4 !important;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  margin-left: 20px !important;
  width: 10px !important ;
  margin-right: 70px !important;
  background: white;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected:hover {
  background: white;
}
.visible.transition {
  margin-left: -60px !important;
}
