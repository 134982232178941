@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");


.maincontainer {
    background: var(--app-background);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    margin: 0px;
  }

.header{
    font-family: "nunito";
    font-size: 26px;
    font-weight: 400;
    padding-left: 5px;
}

.add{
    display: flex;
    justify-content: space-between;
    align-items: middle;
    padding: 20px 0px;
}

.addbutton{
    background: var(--button-background);
    border: none;
    border-radius: 5%;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: 500;
    font-size: 18px;
    padding: 0px 20px;
}

.link{
    color: var(--sub-heading-color);
    font-size: 14px;
    font-family: "nunito";
    font-weight: 400;
}

.link:hover{
    cursor: pointer;
    text-decoration: underline;
}

.addbutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.icon:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.btn_color{
    background: var(--button-background);
    border: none;
    border-radius: 5px;
    height: 30px;
    width: fit-content;
    color: var(--button-text);
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
}

.btn_colordis {
    background-color: grey;
    color: white;
    cursor: not-allowed;
    font-family: "nunito";
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    border: none;
    height: 30px;
    border-radius: 5px;
    width: fit-content;
  }

.btn_color:hover{
    cursor: pointer;
    transform: scale(1.1);
}
